export const environment = {
  BackEndURL: 'https://api.staging.madfu.com.sa',
  registerLink: 'https://onelink.to/ekb96t',
  production: false,
  username: 'madfuUser',
  password: 'rjp2WOx3mdC6dpKZMUZj',
  apikey: '0b9315aa-22ed-48cb-8ba4-a',
  appcode: 'BNPLU',
  androidStoreLink:
    'https://play.google.com/store/apps/details?id=com.sa.app.madfuser',
  iOSStoreLink:
    'https://apps.apple.com/us/app/madfu-%D9%85%D8%AF%D9%81%D9%88%D8%B9/id1658723268',
  redirectPeriodInMilliSecond: 5000,
};
