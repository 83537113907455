import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPaymentComponent } from './pages/success-payment/success-payment.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpHelperService } from './services/base/http-helper.services';
import { TokenInterceptorService } from './interceptors/token.interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmPaymentVaTelComponent } from './pages/confirm-payment-va-tel/confirm-payment-va-tel.component';
import { ThreeDsPaymentComponent } from './pages/three-ds-payment/three-ds-payment.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LeadingZeroDirective } from './directives/leading-zero.directive';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ApplePayComponent } from './pages/apple-pay/apple-pay.component';
import { CommonModule, DatePipe } from '@angular/common';
import { HijriDatePickerComponent } from './components/shared/hijri-date-picker/hijri-date-picker.component';
import { OtpTransactionConfirmationComponent } from './pages/otp-transaction-confirmation/otp-transaction-confirmation.component';
import { LoginComponent } from './pages/login/login.component';
import { FormComponent } from './components/login/form/form.component';
import { CancelOrderModalComponent } from './components/shared/cancel-order-modal/cancel-order-modal.component';
import { EmptyPageComponent } from './pages/empty-page/empty-page.component';
import { LoginOtpComponent } from './pages/login-otp/login-otp.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaymentOptionsComponent } from './pages/payment-options/payment-options.component';
import { PagesLayoutComponent } from './layout/pages-layout/pages-layout.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ConfirmPaymentUsingBirthDateComponent } from './pages/confirm-payment-using-birth-date/confirm-payment-using-birth-date.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { PaymentScheduleComponent } from './components/shared/payment-schedule/payment-schedule.component';
import { PromoCodeComponent } from './components/payment-options/promo-code/promo-code.component';
import { ShortenPipe } from './pipes/shorten.pipe';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { OtpVerificationComponent } from './components/sign-up/otp-verification/otp-verification.component';
import { PersonalDataComponent } from './components/sign-up/personal-data/personal-data.component';
import { ToastrModule } from 'ngx-toastr';
import { NationalIdVerificationComponent } from './components/sign-up/national-id-verification/national-id-verification.component';
import { NationalIdConfirmationComponent } from './components/sign-up/national-id-confirmation/national-id-confirmation.component';
import { NafathVerificationComponent } from './components/sign-up/nafath-verification/nafath-verification.component';
import { PhoneVerificationFailedComponent } from './components/sign-up/phone-verification-failed/phone-verification-failed.component';
import { PhoneVerificationLimitReachedComponent } from './components/sign-up/phone-verification-limit-reached/phone-verification-limit-reached.component';
import { ChangePhoneNumberComponent } from './components/sign-up/change-phone-number/change-phone-number.component';
import { TermsConfirmationComponent } from './components/sign-up/terms-confirmation/terms-confirmation.component';
import { UnequalInstallmentsPaymentMessageComponent } from './components/payment-options/unequal-installments-payment-message/unequal-installments-payment-message.component';
import { UnavailableWidgetComponent } from './components/payment-options/unavailable-widget/unavailable-widget.component';
import { ShoppingCartBarComponent } from './components/payment-options/shoping-cart-bar/shopping-cart-bar.component';
import { OptionsSelectionComponent } from './components/payment-options/options-selection/options-selection.component';
import { PaymentMethodsStepComponent } from './components/payment-options/payment-methods-step/payment-methods-step.component';
import { AddCardIframeComponent } from './components/payment-options/add-card-iframe/add-card-iframe.component';
import { SamaTermsModalComponent } from './components/payment-options/sama-terms-modal/sama-terms-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FormComponent,
    CancelOrderModalComponent,
    EmptyPageComponent,
    LoginOtpComponent,
    PagesLayoutComponent,
    PaymentOptionsComponent,
    SuccessPaymentComponent,
    ConfirmPaymentUsingBirthDateComponent,
    HijriDatePickerComponent,
    OtpTransactionConfirmationComponent,
    ThreeDsPaymentComponent,
    ApplePayComponent,
    LoaderComponent,
    ErrorPageComponent,
    PaymentScheduleComponent,
    PromoCodeComponent,
    ConfirmPaymentVaTelComponent,
    LeadingZeroDirective,
    ShortenPipe,
    SignUpComponent,
    OtpVerificationComponent,
    PersonalDataComponent,
    NationalIdVerificationComponent,
    NationalIdConfirmationComponent,
    NafathVerificationComponent,
    PhoneVerificationFailedComponent,
    PhoneVerificationLimitReachedComponent,
    ChangePhoneNumberComponent,
    TermsConfirmationComponent,
    UnequalInstallmentsPaymentMessageComponent,
    UnavailableWidgetComponent,
    ShoppingCartBarComponent,
    OptionsSelectionComponent,
    PaymentMethodsStepComponent,
    AddCardIframeComponent,
    SamaTermsModalComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbDatepickerModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RoundProgressModule,
    CdkStepperModule,
    NgStepperModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    HttpHelperService,
    TranslateService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
