<form *ngIf="paymentOptionRes?.length && !loading">
  <div class="payment-options-form align-items-center">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="payment-options-form-widget">
          <p class="payment-method-title">
            {{ "choice-of-payment" | translate }}
          </p>

          <div class="payment-methods-widget">
            <div class="accordion accordion-flush" id="paymentMethodsAccordion">
              <div class="accordion-item" *ngFor="let item of paymentOptionRes">
                <h2
                  class="accordion-header"
                  id="{{ 'flush-heading-' + item.paymentOptionType }}"
                  [ngClass]="[
                    item.paymentOptionType === selectedPaymentOptionType
                      ? 'active'
                      : '',
                    'accordion-header-' + item.paymentOptionType
                  ]"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    [ngClass]="[
                      item.paymentOptionType === expandedPaymentOption
                        ? 'active'
                        : ''
                    ]"
                    (click)="handleOpenCollapse(item)"
                  >
                    <div>
                      <p class="title">{{ item.title }}</p>
                      <p class="sub-title">{{ item.description }}</p>
                    </div>
                  </button>
                </h2>

                <div
                  id="{{ 'flush-collapse-' + item.paymentOptionType }}"
                  class="accordion-collapse collapse"
                  attr.aria-labelledby="{{
                    'flush-heading-' + item.paymentOptionType
                  }}"
                  data-bs-parent="#paymentMethodsAccordion"
                >
                  <div
                    *ngIf="
                      item.paymentOptionType != PaymentOptionType.PayInFull
                    "
                    class="accordion-body"
                  >
                    <ul
                      class="installments"
                      *ngIf="
                        item.paymentOptionType == PaymentOptionType.Installments
                      "
                    >
                      <li
                        class="installment-data"
                        *ngFor="let itemData of item.options"
                      >
                        <label class="container-radio">
                          <input
                            type="radio"
                            name="paymentOptionGroup"
                            [id]="itemData.optionId"
                            (change)="
                              onChangeOptionIdHandler(itemData, item.title)
                            "
                            [(ngModel)]="selectedPaymentOptionId"
                            [value]="itemData.optionId"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="labels" [for]="itemData.optionId">
                          <span class="number-of-installment">
                            {{ itemData.noOfInstallments }}
                            {{ "payments" | translate }}
                          </span>
                          <span class="due-now">
                            {{ "due-now" | translate }}:
                            <span class="amount">{{
                              itemData.paidAmountNow
                            }}</span>
                            <span class="rial">{{ "Riyal" | translate }}</span>
                          </span>
                        </label>
                      </li>
                    </ul>

                    <ul
                      class="installments"
                      *ngIf="
                        item.paymentOptionType == PaymentOptionType.Pay99
                      "
                    >
                      <li
                        class="installment-data"
                        *ngFor="let itemData of item.options"
                      >
                        <label class="container-radio">
                          <input
                            type="radio"
                            name="paymentOptionGroup"
                            [id]="itemData.optionId"
                            (change)="
                              onChangeOptionIdHandler(itemData, item.title)
                            "
                            [(ngModel)]="selectedPaymentOptionId"
                            [value]="itemData.optionId"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="labels" [for]="itemData.optionId">
                          <span class="number-of-installment">
                            {{ itemData.noOfInstallments }}
                            {{ "payments" | translate }}
                          </span>
                          <span class="due-now">
                            {{ "due-now" | translate }}:
                            <span class="amount">{{
                              itemData.paidAmountNow
                            }}</span>
                            <span class="rial">{{ "Riyal" | translate }}</span>
                          </span>
                        </label>
                      </li>
                    </ul>

                    <div
                      *ngIf="item.paymentOptionType == PaymentOptionType.PayDay"
                    >
                      <div class="payday-widget">
                        <button
                          type="button"
                          (click)="
                            previousPayDayOptionHandler(
                              selectedPaydayOption,
                              item.title
                            )
                          "
                        >
                          -
                        </button>
                        <div class="progressbar-widget">
                          <div
                            class="progressbar"
                            [ngStyle]="{
                              width:
                                selectedPaydayOption.percentageDownPayment + '%'
                            }"
                          ></div>
                          <span class="pay-percentage">
                            <span class="paid-amount-now">
                              {{ selectedPaydayOption.paidAmountNow }}
                            </span>
                            <span class="rial"> {{ "Riyal" | translate }}</span>
                            <span class="slash">/</span>
                            <span class="percentage-down-payment">
                              {{ selectedPaydayOption.percentageDownPayment }}%
                            </span>
                          </span>
                        </div>
                        <button
                          type="button"
                          (click)="
                            nextPayDayOptionHandler(
                              selectedPaydayOption,
                              item.title
                            )
                          "
                        >
                          +
                        </button>
                      </div>
                      <div class="remaining-amount">
                        <p class="remaining-amount-text">
                          {{ "remaining-amount" | translate }}
                        </p>
                        <p class="installment-amount">
                          {{ selectedPaydayOption.installmentAmount }}
                          <span class="rial"> {{ "Riyal" | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <app-payment-schedule
          [paymentPlan]="selectedPaymentOption?.paymentPlan"
          [cashBackEnable]="cashBackEnable"
          [paidAmountNowAfterWallet]="
            selectedPaymentOption?.paidAmountNowAfterWallet ?? 0
          "
          [isFreeLastInstalment]="
            selectedPaymentOption?.isFreeLastInstalment ?? false
          "
          [paymentOptionType]="selectedPaymentOptionType"
        ></app-payment-schedule>

        @if(selectedPaymentOption?.isOverAmount) {
        <app-unequal-installments-payment-message
          [downPayment]="selectedPaymentOption?.paymentPlan[0]?.amount ?? 0"
        />
        }
      </div>
    </div>

    <div class="cashback-widget form-row align-items-center">
      <div class="col-12">
        <div class="row">
          <div class="d-flex justify-content-between align-items-center">
            <div class="labels">
              <span class="payment-method-title mt-4 mb-3">
                {{ "pay-using-cashback" | translate }}
              </span>
              <div class="col-12 your-balance">
                {{ "Your-balance" | translate }} :
                <span>{{ balance }} {{ "Riyal" | translate }}</span>
              </div>
            </div>
            <div class="toggle-switch">
              <input
                type="checkbox"
                id="cashBackId"
                class="toggle-input"
                (change)="checkCashBackValueHandler($event)"
                [disabled]="!isCashBackEnabled"
              />
              <label for="cashBackId" class="toggle-label"></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="paymentOptionRes">
      <div class="col-auto">
        <button
          [disabled]="!selectedPaymentOption"
          type="button"
          class="btn btn-primary col-12 mt-3 pay-btn"
          (click)="openPaymentMethodsHandler()"
        >
          {{ "pay" | translate }} {{ payNowAmount }}
          <span class="rial">{{ "Riyal" | translate }}</span>
          {{ "now" | translate }}

          <img
            src="../../../assets/images/login/side-arrow.svg"
            class="img-fluid ms-2"
            alt="arrow"
          />
        </button>
      </div>
    </div>
  </div>
</form>
