import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sama-terms-modal',
  standalone: false,
  templateUrl: './sama-terms-modal.component.html',
  styleUrl: './sama-terms-modal.component.scss',
})
export class SamaTermsModalComponent {
  @Output() closeSamaTerms = new EventEmitter();

  closeModal() {
    this.closeSamaTerms.emit();
  }
}
