<div
  class="modal fade sama-terms-modal show"
  id="samaTermsModal"
  tabindex="-1"
  aria-labelledby="samaTermsModalLabel"
  aria-hidden="false"
  style="display: block"
  role="dialog"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <p class="title">
          {{ "sama-terms-title" | translate }}
        </p>
        <p class="subtitle">
          {{ "sama-terms-body-1" | translate }}
          <span>{{ "sama-terms-body-2" | translate }}</span>
          {{ "sama-terms-body-3" | translate }}
        </p>

        <button
          type="button"
          class="btn btn-primary col-12"
          (click)="closeModal()"
        >
          {{ "agree" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
