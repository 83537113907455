import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { ExecuteTransactionAfterApplePayReq } from 'src/app/models/ExecuteTransactionAfterApplePay';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { PaymentData } from 'src/app/enums/PaymentType';

@Component({
  selector: 'app-apple-pay',
  templateUrl: './apple-pay.component.html',
  styleUrls: ['./apple-pay.component.scss'],
})
export class ApplePayComponent implements OnInit {
  loading: boolean = false;

  paidAmountNow: number = 0;
  merchantReference: string = '';
  customerName: string = '';
  orderId: number | undefined;
  isErrorModalOpened = false;

  constructor(
    private paymentService: PaymentService,
    private paymentProcessService: PaymentProcessService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    if (!(window as any).ApplePaySession) {
      this.openErrorModal();

      return;
    }

    this.subscribePaymentData();
  }

  subscribePaymentData() {
    this.paymentProcessService.paymentData
      .pipe(take(1))
      .subscribe((paymentData: PaymentData) => {
        this.paidAmountNow = paymentData.paidAmountNow;
        this.merchantReference = paymentData.merchantReference;
        this.customerName = paymentData.customerName;
        this.orderId = paymentData.orderId;
      });
  }

  handleApplePaySession() {
    this.loading = true;

    const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
      countryCode: 'SA',
      currencyCode: 'SAR',
      merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
      supportedNetworks: ['visa', 'masterCard', 'MADA'],
      total: {
        label: 'MADFU',
        type: 'final',
        amount: this.paidAmountNow.toString() ?? '0',
      },
    };

    const session = new ApplePaySession(1, paymentRequest);

    session.onvalidatemerchant = (
      event: ApplePayJS.ApplePayValidateMerchantEvent
    ) => {
      this.paymentService.ApplePayPaymentSession().subscribe({
        next: (response) => {
          session.completeMerchantValidation(response?.data || {});
        },
        error: (error) => {
          console.error('Merchant validation failed', error);
          this.loading = false;
        },
      });
    };

    session.onpaymentauthorized = (
      event: ApplePayJS.ApplePayPaymentAuthorizedEvent
    ) => {
      const paymentData = event.payment.token.paymentData;

      let isApproved = false;
      const paymentReq: ExecuteTransactionAfterApplePayReq = {
        order_id: this.orderId ?? 0,
        amazonPaymentPage: {
          amount: this.paidAmountNow.toString() ?? '',
          customerName: this.customerName,
          merchant_Reference: this.merchantReference.toString(),
        },
        applePayPaymentData: {
          applePaymentMethod: {
            displayName: event.payment.token?.paymentMethod.displayName,
            network: event.payment.token?.paymentMethod.network,
            type: event.payment.token?.paymentMethod.type,
          },
          data: paymentData.data,
          header: {
            ephemeralPublicKey: paymentData.header?.ephemeralPublicKey,
            publicKeyHash: paymentData.header?.publicKeyHash,
            transactionId: paymentData.header?.transactionId,
          },
          signature: paymentData.signature,
          transactionIdentifier: paymentData.transactionIdentifier,
          version: paymentData.version,
        },
      };

      const result = {
        status: ApplePaySession.STATUS_SUCCESS,
      };
      session.completePayment(result);

      this.paymentService
        .ExecuteTransactionAfterApplePay(paymentReq)
        .subscribe({
          next: (response) => {
            setTimeout(() => {
              this.zone.run(() => {
                this.loading = false;

                isApproved = response?.data?.success ?? false;
                console.log(
                  'ExecuteTransactionAfterApplePay',
                  response?.data?.success,
                  response
                );

                this.checkIsApproved(isApproved);
              }, 50);
            });
          },
          error: (error) => {
            this.zone.run(() => {
              this.openErrorModal(error.error.detail);
            });
          },
        });
    };

    session.oncancel = (event) => {
      this.openErrorModal();
    };

    session.begin();
  }

  checkIsApproved(isApproved: any) {
    console.log('checkIsApproved', isApproved);

    if (isApproved) {
      this.paymentProcessService.openSuccessPaymentPage();
    } else {
      console.log('checkIsApproved not approved');
      this.openErrorModal();
    }
  }

  openErrorModal(message: string = '') {
    console.log('openErrorModal', this.loading);
    console.log('isErrorModalOpened', this.isErrorModalOpened);

    if (!this.isErrorModalOpened) {
      this.isErrorModalOpened = true;

      console.log('should open error modal');

      this.paymentProcessService.openErrorModal(
        this.translate.instant('paying-problem-title'),
        message || this.translate.instant('paying-problem-subtitle')
      );
    }

    this.cdr.detectChanges();
  }
}
