import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { SignInReq, SignInRes } from 'src/app/models/siginIn';
import { TokenInitReq } from 'src/app/models/token-init';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpSendOTPReq, SignUpSendOTPRes } from 'src/app/models/signUp';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ANALYTICS_EVENTS } from 'src/app/constants';

@Component({
  selector: 'app-login-form',
  standalone: false,
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent {
  @Input() orderDetailsRes: OrderDetailsRes | undefined;

  loginForm: FormGroup;
  isRequestPending: boolean = false;
  msgError: string = '';
  deviceInfo: any;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    private readonly signUpService: SignUpService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.loginForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.pattern('^5[0-9]{8}$')]],
    });
  }

  ngOnInit(): void {
    this.setUserMobileNumberInFormData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['orderDetailsRes']) {
      this.setUserMobileNumberInFormData();
    }
  }

  setUserMobileNumberInFormData() {
    this.loginForm.patchValue({
      mobile: this.orderDetailsRes?.data?.guestOrderData?.customerMobile
        ? this.orderDetailsRes?.data?.guestOrderData?.customerMobile.replace(
            /^0+/,
            ''
          )
        : '',
    });
  }

  checkValidation() {
    this.checkMobileNumberChange();

    const mobileControl = this.loginForm.get('mobile')!;

    if (mobileControl.hasError('incorrectMobile')) {
      mobileControl.setErrors(null);
    }
  }

  handleEnterPress() {
    if (!this.isRequestPending && this.loginForm.valid) {
      this.handleLogin();
    }
  }

  handleLogin() {
    this.isRequestPending = true;
    this.getInitToken(() => {
      this.login();
      this.analyticsService.sendEvent(ANALYTICS_EVENTS.SIGN_IN.CREATE_ACCOUNT);
    });
  }

  getInitToken(callback: Function) {
    const tokenReq: TokenInitReq = {
      systemInfo: window.navigator.userAgent,
      uuid: uuidv4().replace(/-/g, ''),
    };

    this.authService.TokenInit(tokenReq).subscribe((res: any) => {
      localStorage.setItem('token', res.data.token);

      callback();
    });
  }

  login() {
    const signinReq: SignInReq = {
      mobile: this.loginForm.value.mobile,
      orderId: this.orderDetailsRes?.data?.orderId,
      inAppOtpNotification: true,
    };

    localStorage.setItem('mobile', this.loginForm.value.mobile);

    this.authService.SignIn(signinReq).subscribe({
      next: this.handleSignInSuccess.bind(this),
      error: this.handleSignInError.bind(this),
    });
  }

  handleSignInSuccess(res: SignInRes) {
    if (res.succeeded) {
      localStorage?.setItem('otpId', res.data.otpId!);

      this.authService.setHaveActiveSession(res.data.haveActiveSession);
      this.openOTPVerificationPage();
    } else {
      this.msgError = res.message!;
      this.loginForm.get('mobile')?.setErrors({ incorrectMobile: true });

      this.getInitToken(() => {
        this.analyticsService.sendEvent(
          ANALYTICS_EVENTS.SIGN_IN.UNREGISTERED_MOBILE
        );
      });
    }

    this.isRequestPending = false;
  }

  handleSignInError(error: any) {
    this.isRequestPending = false;

    this.msgError = error.error.detail;
    this.loginForm.get('mobile')?.setErrors({ incorrectMobile: true });

    this.getInitToken(() => {
      this.analyticsService.sendEvent(
        ANALYTICS_EVENTS.SIGN_IN.UNREGISTERED_MOBILE
      );
    });
  }

  checkMobileNumberChange() {
    if (
      this.orderDetailsRes?.data?.guestOrderData?.customerMobile.replace(
        /^0+/,
        ''
      ) !== this.loginForm.value.mobile
    ) {
      this.getInitToken(() => {
        this.analyticsService.sendEvent(
          ANALYTICS_EVENTS.SIGN_IN.NEW_MOBILE_NUMBER
        );
      });
    }
  }

  openOTPVerificationPage() {
    this.router.navigate(['/login-otp-verification'], {
      queryParams: {
        ...this.route.snapshot.queryParams,
      },
    });
  }

  handleSignUp() {
    if (this.loginForm.valid) {
      this.isRequestPending = true;
      this.getInitToken(() => {
        this.sendRegisterOTP();
        this.analyticsService.sendEvent(
          ANALYTICS_EVENTS.SIGN_IN.CREATE_ACCOUNT
        );
      });
    }
  }

  sendRegisterOTP() {
    const otpReq: SignUpSendOTPReq = {
      mobile: this.loginForm.value.mobile,
      mobileCountryCode: '+966',
    };

    localStorage.setItem('mobile', this.loginForm.value.mobile);

    this.signUpService.SendOTP(otpReq).subscribe({
      next: this.handleSendOTPSuccess.bind(this),
      error: this.handleSendOTPError.bind(this),
    });
  }

  handleSendOTPSuccess(res: SignUpSendOTPRes) {
    if (res.succeeded) {
      this.handleOpenSignUpOtpPage(res.data.idGuid);
    } else {
      this.msgError = res.message!;
      this.loginForm.get('mobile')?.setErrors({ incorrectMobile: true });
    }

    this.isRequestPending = false;
  }

  handleSendOTPError(error: any) {
    this.msgError = error.error.detail;
    this.loginForm.get('mobile')?.setErrors({ incorrectMobile: true });
    this.isRequestPending = false;
  }

  handleOpenSignUpOtpPage(idGuid: string) {
    this.signUpService.setMobileAndIdGuid(this.loginForm.value.mobile, idGuid);

    this.router.navigate(['/sign-up'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }
}
