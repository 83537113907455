import { Injectable } from '@angular/core';
import { environment } from 'src/app/environment/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private router: Router) {}

  public HandleSuccessPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.success != null) {
      this.RedirectToLink(obj?.data?.merchantUrls?.success);
    }
  }

  public HandleErrorPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.failure != null) {
      this.RedirectToLink(obj?.data?.merchantUrls?.failure);
    }
  }

  public HandleCancelPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.cancel != null) {
      this.RedirectToLink(obj?.data?.merchantUrls?.cancel);
    }
  }

  public HandleDirectCancelPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.cancel != null) {
      this.DirectRedirectToLink(obj?.data?.merchantUrls?.cancel);
    }
  }

  private RedirectToLink(link: string) {
    setTimeout(() => {
      window.location.href = link;
      localStorage.clear();
    }, environment.redirectPeriodInMilliSecond);
  }
  public DirectRedirectToLink(link: string) {
    window.location.href = link;
    localStorage.clear();
  }
}
