<div class="payment-schedule">
  @if(paymentPlan){
  <p class="section-title">{{ "payment-schedule" | translate }}</p>
  <div *ngFor="let item of paymentPlan; let i = index">
    <div class="payment-widget-item">
      <div class="payment-widget-item-data">
        <round-progress
          [current]="getCircleProgress(i + 1)"
          [max]="100"
          [color]="'#100ca1'"
          [background]="'#e0e0e0'"
          [radius]="17"
          [stroke]="1"
          [semicircle]="false"
          [rounded]="true"
          [clockwise]="false"
          [responsive]="false"
          [duration]="10"
          [animationDelay]="0"
        />

        <div class="circle" [ngClass]="{ 'vertical-line': i >= 1 }">
          <span>{{ i + 1 }}</span>
        </div>

        <div class="data">
          <span class="data-name">
            {{ "amount-to-pay" | translate }}
          </span>

          <span class="data-date">
            {{ item.dueDate | date : "dd/MM/yyyy" }}
          </span>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <span class="payment-amount">
          <span>
            @if (i == 0 && this.cashBackEnable) {
            <span class="main-amount">
              {{ item.amount }}
            </span>
            <span> {{ this.paidAmountNowAfterWallet }}</span>
            } @else if(isFreeLastInstalment && i == paymentPlan.length - 1 &&
            (paymentOptionType == paymentOptionTypes.Installments ||
            paymentOptionType == paymentOptionTypes.Pay99)){
            <span class="main-amount gift-by-moov-amount">
              {{ item.amount }}
              <span class="rial">
                {{ "Riyal" | translate }}
              </span>
            </span>
            <!--
            <span
              class="gift-by-moov"
              *ngIf="paymentOptionType != paymentOptionTypes.Pay99"
            >
              <img
                src="/assets/images/payment-options/gift-by-moov.png"
                alt="gift by moov"
                class="img-fluid me-1"
              />
              {{ "gift-by-moov" | translate }}
            </span>
            -->
            } @else if(i != 0 || !this.cashBackEnable){
            {{ item.amount }}
            }
          </span>

          @if(!(isFreeLastInstalment && i == paymentPlan.length - 1 &&
          paymentOptionType == paymentOptionTypes.Installments) &&
          paymentOptionType != paymentOptionTypes.Pay99) {
          <span class="rial">
            {{ "Riyal" | translate }}
          </span>
          }
        </span>
      </div>
    </div>
  </div>
  } @else {
  <div class="payment-schedule-not-selected">
    <img
      src="../../../assets/images/payment-schedule-not-selected.svg"
      alt="payment schedule not selected"
      class="img-fluid"
    />

    <p class="section-title">{{ "payment-schedule" | translate }}</p>

    <p class="not-selected-text">
      {{ "payment-schedule-appear-when-select" | translate }}
    </p>
  </div>
  }
</div>
