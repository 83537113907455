import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { LoaderService } from './services/Loader.Service';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutService } from './services/checkout.service';
import { GetOrderDetailsReq, OrderDetailsRes } from './models/getOrderDetails';
import { ChangeLanguageService } from './services/helper/change-language.service';
import { ApplicationLanguages } from 'src/app/enums/lang';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorPopupService } from './services/error-popup.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './services/analytics.service';
import { AuthService } from './services/auth.service';
import { TokenInitReq } from './models/token-init';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  orderDetailsRes: OrderDetailsRes | undefined;
  applicationLang = ApplicationLanguages;
  title = 'Checkout';
  isLoading: boolean = false;

  destroyRef = inject(DestroyRef);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly loaderService: LoaderService,
    private readonly checkoutService: CheckoutService,
    private readonly changeLanguageService: ChangeLanguageService,
    private readonly errorPopupService: ErrorPopupService,
    private readonly translate: TranslateService,
    private readonly analyticsService: AnalyticsService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.subscribeToLoader();
    this.handleRouteQueryParams();
  }

  private subscribeToLoader(): void {
    this.loaderService.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading.valueOf();

      if (
        this.orderDetailsRes &&
        !this.orderDetailsRes?.succeeded &&
        !this.isLoading
      ) {
        this.openErrorBox(
          this.orderDetailsRes?.data.title,
          this.orderDetailsRes?.data.message
        );
      }
    });
  }

  private handleRouteQueryParams(): void {
    this.route.queryParamMap.subscribe(() => {
      const token = this.route.snapshot.queryParamMap.get('token');
      const oldToken = localStorage.getItem('user-token');

      if (token) {
        if (!this.isLoginPage() && oldToken !== token) {
          this.router.navigate(['/Payment'], {
            queryParams: { ...this.route.snapshot.queryParams },
          });

          this.fetchOrderDetails(token);
        } else {
          this.getOrderDetailsByToken(token);
        }
      }
      this.subscribeOrderDetails();
    });
  }

  get savedLanguage() {
    return localStorage.getItem('lang');
  }

  getOrderDetailsByToken(token: string) {
    if (this.isLoginPage() || !localStorage.getItem('orderDetailsRes')) {
      this.fetchOrderDetails(token);
    } else {
      this.getOrderDetailsFromLocalStorage();
    }
  }

  fetchOrderDetails(token: string) {
    localStorage.clear();
    localStorage.setItem('user-token', token);

    const tokenReq: TokenInitReq = {
      systemInfo: window.navigator.userAgent,
      uuid: uuidv4().replace(/-/g, ''),
    };

    this.authService.TokenInit(tokenReq).subscribe((res: any) => {
      localStorage.setItem('token', res.data.token);

      const getOrderDetailsReq: GetOrderDetailsReq = { token: token };

      this.checkoutService.GetOrderDetails(getOrderDetailsReq);
    });
  }
  getOrderDetailsFromLocalStorage() {
    const savedOrderDetails = localStorage.getItem('orderDetailsRes');
    if (savedOrderDetails) {
      const orderDetailsRes = JSON.parse(savedOrderDetails);
      this.checkoutService.orderDetails.next(orderDetailsRes);
    }
  }

  isLoginPage() {
    return (
      this.router.url.toLowerCase().includes('/payment?') ||
      this.router.url.toLowerCase() === '/payment'
    );
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: OrderDetailsRes | undefined) => {
        this.orderDetailsRes = res;
        if (res) {
          this.setAppLanguage(res?.data?.guestOrderData?.lang ?? 'en');
          this.analyticsService.orderId = res?.data?.orderId;

          if (!res?.succeeded && !this.isLoading) {
            this.openErrorBox(res?.data.title, res?.data.message);
          }
        }
      });
  }

  setAppLanguage(orderLang: string) {
    const currentAppLang = localStorage.getItem('lang');

    const lang =
      currentAppLang ??
      (orderLang == this.applicationLang.Ar
        ? this.applicationLang.Ar
        : this.applicationLang.En);

    this.changeLanguageService.changeLanguage(lang, false);
  }

  openErrorBox(
    title: string = this.translate.instant('paying-problem-title'),
    message: string = this.translate.instant('paying-problem-subtitle')
  ) {
    this.errorPopupService.openModal(title, message);
  }
}
