export const ANALYTICS_EVENTS = {
  SIGN_IN: {
    SIGN_IN: 'sign_in',
    CREATE_ACCOUNT: 'create_acc',
    NEW_MOBILE_NUMBER: 'new_mobilenum',
    UNREGISTERED_MOBILE: 'sign_in_unreg_phone',
  },
  SIGN_IN_OTP: {
    CORRECT_OTP: 'correct_otp',
    WRONG_OTP: 'wrong_otp',
    RESEND_OTP: 'resend_otp',
  },
  PAYMENT_OPTIONS: {
    SPLIT_PAYMENTS: 'split_payments',
    SPLIT_PAYMENTS_INTO_MONTHS: 'split_payments_${period}_months',
    PAYDAY: 'payday',
    PAYDAY_PERCENTAGE: 'payday_${percentage}_%',
    PAY_NOW: 'paymentoption_paybutton',
    CASHBACK_ENABLED: 'cashback_enabled',
    CASHBACK_DISABLED: 'cashback_disabled',
    FINANCIAL_LIMIT_RECEIVED: 'financial_limit_received',
  },
  PAYMENT_METHODS: {
    VISA_CLICK: 'visa_click',
    MADA_CLICK: 'mada_click',
    APPLE_CLICK: 'apple_click',
    TERMS_AND_CONDITIONS_CHECK: 't&c_check',
    PAY_NOW: 'pay_now',
    PROMO_CODE_APPLY: 'promo_code_applied',
    WRONG_PROMO_CODE: 'wrong_promo_code',
    PROMO_CODE_REMOVE: 'promo_code_disable',
  },
  BIRTH_DATE_CONFIRMATION: {
    HIJRI_DOB: 'hijri_dob',
    GREGORIAN_DOB: 'greg_dob',
    ENTER_DOB: 'enter_dob',
    CONFIRM_DOB: 'dob_confirm',
  },
  PAYMENT_OTP: {
    CORRECT_OTP: 'correct_otp_confirm',
    WRONG_OTP: 'wrong_otp_confirm',
    RESEND_OTP: 'resend_otp_confirm',
  },
  CONFIRMATION_CALL: {
    CALL_CONFIRM: 'call_confirm',
  },
  PAYMENT_SUCCESS: {
    GO_HOME: 'go_home',
  },
  SHARED: {
    BACK: 'back',
    CANCEL_ORDER: 'cancel_order',
    CANCEL_ORDER_CONFIRMATION: 'cancel_order_confirm',
    CANCEL_ORDER_DECLINE: 'cancel_order_decline',
  },
};
